import { Gallery, Heading, Layout } from "../../../components/utils";

import blueprint from "../../../img/2d/blueprint of a broken home.jpg";
import camelia from "../../../img/2d/cameliascreaming.jpg";
import cartagena from "../../../img/2d/cartagena.jpg";
import cave from "../../../img/2d/cave.jpg";
import dolor from "../../../img/2d/dolor por la patria.jpg";
import flames from "../../../img/2d/flames.jpg";
import fruitinbox from "../../../img/2d/fruitinbox.jpg";
import fruitonthestair from "../../../img/2d/fruitonthestair.jpg";
import hands from "../../../img/2d/hands.jpg";
import uma from "../../../img/2d/uma.jpg";
import watch from "../../../img/2d/watch finished.jpg";

export const TwoDimensional = () => {
  return (
    <>
      <Layout grid={8}>
        <Heading
          period={2}
          start={2}
          gridStart={7}
          gridEnd={9}
          tag="h2"
          text="2d"
        />
        <Gallery
          gridStart={1}
          gridEnd={9}
          height={500}
          grid={2}
          gutter={16}
          data={[
            { image: blueprint, caption: "blueprint of a broken home" },
            { image: camelia, caption: "Bleecker St" },
            { image: cartagena, caption: "Cartagena" },
            { image: cave, caption: "discovery" },
            { image: dolor, caption: "dolor por la patria" },
            { image: flames, caption: "2,795" },
            { image: fruitinbox, caption: "Still life #1" },
            { image: fruitonthestair, caption: "Still life #2" },
            { image: hands, caption: "they look like a corpse" },
            { image: uma, caption: "Uma" },
            { image: watch, caption: "Watch" },
          ]}
        />
      </Layout>
    </>
  );
};
