import { Gallery, Heading, Layout } from "../../../components/utils";

import bullhill from "../../../img/mixedmedia/bullhill.jpg";
import colony from "../../../img/mixedmedia/colony erased.jpg";
import nectarine from "../../../img/mixedmedia/nectarine.jpg";
import no_olvidamos from "../../../img/mixedmedia/no olvidamos.jpg";
import vaporwave from "../../../img/mixedmedia/vaporwave.jpg";

export const MixedMedia = () => {
  return (
    <>
      <Layout grid={8}>
        <Heading
          period={6}
          start={0}
          gridStart={1}
          gridEnd={4}
          tag="h2"
          text="mixed media"
        />
        <Gallery
          gridStart={1}
          gridEnd={9}
          height={500}
          grid={2}
          gutter={16}
          data={[
            { image: bullhill, caption: "bull hill" },
            { image: colony, caption: "colony erased" },
            { image: nectarine, caption: "nectarine" },
            { image: no_olvidamos, caption: "no olvidamos" },
            { image: vaporwave, caption: "vaporwave" },
          ]}
        />
      </Layout>
    </>
  );
};
