import { Gallery, Heading, Layout } from "../../../components/utils";

import alientechnology from "../../../img/sculpture/alientechnology.jpg";
import alientechnology_clear from "../../../img/sculpture/alientechnology_clear.png";
import feet from "../../../img/sculpture/feet.jpg";
import feet_clear from "../../../img/sculpture/feet_clear.png";
import horse from "../../../img/sculpture/horse.jpg";
import horse_clear from "../../../img/sculpture/horse_clear.png";
import organicwinecork from "../../../img/sculpture/organicwinecork.jpg";
import pumpkin from "../../../img/sculpture/pumpkin.jpg";

export const Sculpture = () => {
  return (
    <>
      <Layout grid={8}>
        <Heading
          period={6}
          start={0}
          gridStart={3}
          gridEnd={7}
          tag="h2"
          text="sculpture"
        />
        <Gallery
          gridStart={1}
          gridEnd={9}
          height={500}
          grid={2}
          gutter={16}
          data={[
            {
              image: alientechnology_clear,
              underlayer: alientechnology,
              caption: "Alien technology",
            },
            { image: feet_clear, underlayer: feet, caption: "Untitled" },
            { image: horse_clear, underlayer: horse, caption: "Untitled" },
            { image: organicwinecork, caption: "Organic wine cork" },
            { image: pumpkin, caption: "pumpkin" },
          ]}
        />
      </Layout>
    </>
  );
};
