import { Gallery, Heading, Layout } from "../../../components/utils";

import fall from "../../../img/digital/fall.jpg";
import famouscollage from "../../../img/digital/famouscollage.jpg";
import moon from "../../../img/digital/moon.gif";
import prescriptions from "../../../img/digital/prescriptions.png";

export const Digital = () => {
  return (
    <>
      <Layout grid={8}>
        <Heading
          period={6}
          start={0}
          gridStart={5}
          gridEnd={8}
          tag="h2"
          text="digital work"
        />
        <Gallery
          gridStart={1}
          gridEnd={9}
          height={500}
          grid={2}
          gutter={16}
          data={[
            { image: fall, caption: "first day of fall" },
            { image: famouscollage, caption: "Untitled" },
            { image: moon, caption: "moon" },
            { image: prescriptions, caption: "Prescriptions" },
          ]}
        />
      </Layout>
    </>
  );
};
